@import 'bootstrap';

$white: #ffffff;
$bghead01:#fff;
$bghead01sec:#ccc;

$bghead01cen:#f8931f;
$bghead01cenb:#f26638;
$bgboxproduct:#f8931f;

$bgbalance:#f25522;
#root {
 
  background: linear-gradient(360deg, #011135a3, #01143dab), url('./assets/images/bgstyle4.jpg') center center;
  background-size: cover;
  background-attachment: local;
  font-family: 'Kanit', sans-serif;
  position: relative;
  color: white;
  overflow-x: hidden;
  font-weight: 200;
  min-height: 100vh !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

::-webkit-scrollbar {
  width: 7px;
  background: #333333;
}

.pointer {
  cursor: pointer;
}


/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #385995;
  border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #b58b44;
  border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #e0b060;
}

.linefixed {
  position: fixed;
  right: 80px;
  z-index: 100;
  width: 85px;
  height: auto;
  top: 60%;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
.linefixed img {
  display: block;
  margin: 0 auto;
}

button {
  box-shadow: none !important;
}
.boxbankd{
  border-radius: 10px;
  border: #000 1px solid;
  background-color: #000;
}
.txtgreen{
  color: rgb(175, 181, 178);
}
.txtgreenh{
  color: rgb(14, 204, 122);
}
.txtgreenred{
  color: #ff4c4c;
}
.btnback{

  border: 0px;
 padding-left: 10px;
 padding-right: 10px;
  background-color: rgb(240, 181, 3);
  color: #000;
}
.bgheadg{
    
  background: rgb(29,35,131);
background: linear-gradient(61deg, rgba(29,35,131,1) 0%, rgba(22,39,111,1) 100%);

}
.btnc1{
  background-color: #bd4c4c;
  color: #fff;
  border: 0px solid;
  border-radius: 5px;
}
.btnc2{
  background-color: #e89426;
  color: #fff;
  border: 0px solid;
  border-radius: 5px;
}
.txtyellow{
  color: #e9c707;
  font-weight: bold;
  font-size: 18px;
}
.txtyellow2{
  color: #e9c707;
  font-size: 18px;
}
.btnmor{
 
  border-radius: 20px;
  background: transparent;
  border: #e29f0d 1px solid;
  color: #fff;
  font-size: 12px;
}
.photobankqr{
  max-width: 70px;
}
.photoqr{
  max-width: 200px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn5{
  background-color:#f0ab0a;
  color: #fff !important;
  border-radius: 5px;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100px;
  cursor: pointer;
}
.btnh5{
  border-bottom: #f0ab0a 2px solid;
  color: #fff !important;
  border-radius: 5px;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100px;
  cursor: pointer;
}
.btnactive{
  background-color: #f0ab0a;
 
  padding-top: 10px;
  padding-bottom: 10px;
}
.dot {
  height: 30px;
  width: 30px;
  background-color: #f0ab0a;
  border-radius: 50%;
  display: inline-block;
}
.btnactive3{
  background-color: #09a470;
 
  padding-top: 10px;
  padding-bottom: 10px;
}
.btnactive3x{
  cursor: pointer;
  border-radius: 20px;
 border: #fbcd34 1px solid;
 background-color: #f6c82f;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btnactive2{
  cursor: pointer;
  background-color: #c4c1c1;
  
  padding-top: 10px;
  padding-bottom: 10px;
}
.txtatm{
  background-color: #f86137;
}
.vedio1{
  margin-top: 30px;
  border-top: #f5df4d 1px solid;
}
.atlconfirm3 {
  padding: 7px;
  color: #000;
  background-color: #e5e5e1;
  text-align: left;
  margin-bottom: 3px;
  border-radius: 10px;
  font-size: 14px;
  margin-left: 30px;
  margin-right: 30px;
}
.vedio1 button {
  margin-top: 1px;
  border: none;
  font-weight: 300;
  border-radius: 5px;
  color: white;
  background: linear-gradient(180deg, #03cd10 0, #04c075) !important;
  padding: 5px 15px;
}
.txtdarh{
  border: #e54214 1px solid;
  color: #e6bd09;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}
.btnamount{
  border: 0px solid;
  border-radius: 15px;
  background-color: #dcc076;
  margin: 5px;
  min-width: 100px;
  color: #000;
}
.btnactive2x{
  cursor: pointer;
  border-radius: 20px;
  border: #c4c1c1 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn6{
  background-color:#7b7a79;
  color: #fff !important;
  border-radius: 5px;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100px;
  cursor: pointer;
}
.photobank{
  border-radius: 50%;
}
.txtbalance{
  font-size: 13px;
}
.loader {
  margin: auto;
  border: 2px solid #EAF0F6;
  border-radius: 50%;
  border-top: 2px solid #FF7A59;
  width: 16px;
  height: 16px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.bankcontainer img {
  width: 25px;
  margin: 1px;
  height: auto;
}

.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#fff000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #fff000;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.span1
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.span1:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#fff000;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}

.login-modal {
  .modal-content {
    background: transparent;
  }

  .contentmodal {
    width: 100%;
    max-width: 500px;
    height: auto;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    background: linear-gradient(117deg, #090000, #310207cc);
    border-radius: 20px;
    padding: 10px;
  }

  input {
    background: #00000085;
    width: 100%;
    border-radius: 9px;
    border: none;
    padding: 10px;
    outline: none;
    color: white;
    box-shadow: 0 0 2px white, 0 0 15px #0000009e;
  }

  .btn-login-modal {
    background: linear-gradient(90deg, #fdd826 0%, #fdb311 100%);
    border: 1px solid #fdd522;
    color: white;
  }

  .closeloginbtn {
    position: absolute;
    right: 2px;
    top: -10px;
    border-radius: 50px;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    background: #ff4c4c;
    padding: 0;
    z-index: 3;
    cursor: pointer;
  }
}

@media (max-width: 991px) {
  .bankcontainer img {
    width: 40px;
    margin: 5px;
    height: auto;
  }
}

.logo {
  max-height: 35px;
}

.newsboxhead {
  width: 100%;
  margin: 0 auto;
  height: 30px;
  background: #ccc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-image, .ads-image {
  border-radius: 8px;
}

.img-service {
  max-height: 150px;
}

.btn-login {
  background-image: linear-gradient(0deg, #1c6099, #246fad);
  box-shadow: inset 0 0 0 2px #51a2d4;
  color: $white;
}

.btn-register, .btn-register:hover {
  background-image: linear-gradient(0deg, #e76f2a, #e76f2a);
  box-shadow: inset 0 0 0 2px #feca6b, 0 0 10px #f29e11;
  color: $white;
}

.footer {
  background: linear-gradient(181deg, #051038, #02030d);
  color: white;
  padding-bottom: 0;
  box-shadow: 0 0 10px black;
}

.gold-line {
  width: 100%;
  height: 3px;
  background: linear-gradient(334deg, transparent, #e4a708 30%, #feffba 70%, transparent);
}

.wrapper {
  position: relative;
}

a, a:hover, a:focus {
  color: white;
  text-decoration: none;
  transition: all 0.3s;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

footer {
  color: white;
  position: relative;
  width: calc(100% - 200px);
  margin-left: auto;
  margin-top: 20px;
}

.x-hr-border-glow {
  margin-top: 5px;
  margin-bottom: 0;
  text-align: center;
  height: 2px;
  background: linear-gradient(90deg, rgba(156, 103, 39, 0), rgba(253, 246, 174, .5) 32%, #dcc076 49%, rgba(189, 141, 66, .5) 65%, rgba(253, 246, 174, 0));
}

.leftdetailsec03 h3 {
  color: #ecca70;
}

.img-why-us {
  max-height: 80px;
}

.img-register {
  max-width: 200px;
}

.x-service-wrapper {
  background: linear-gradient(0deg, #000000e0, #00000000);
  padding-bottom: 30px;
}

.copyright {
  background: #000;
  text-align: center;
  padding: 10px 0;
  font-weight: 200;
  font-size: 15px;
  color: #b8b8b8;
  transition: all .3s;
}

@media (max-width: 991px) {
  .copyright {
    padding-bottom: 105px !important;
  }
}

.tagcontainer span {
  display: inline-block;
}

.tagfooter {
  background: #9c0230;
  margin: 5px 2px;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
}

.headerbt {
  display: block;
  font-weight: 300;
  font-size: 25px;
  background: linear-gradient(90deg, #a27a46 0, #f0ddab 50%, #a27a46);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.true-money {
  width: 120px;
}

@media (max-width: 599px) {
  .contenttabsec02 {
    padding-top: 5px;
  }
}

@media (max-width: 599px) {
  .contenttabsec02 {
    padding: 0;
  }
}

.tablinks {
  white-space: nowrap;
}

.contenttabsec02 {
  background: radial-gradient(100% 100% at 50% 0, $bghead01 0, $bghead01sec 100%);
  padding-top: 5px;
}

.tabctsec02 {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.tablinks.active button {
    background: linear-gradient(155deg, $bghead01cen, $bghead01cenb);
  text-shadow: 0 0 10px white, 0 0 20px rgb(22, 21, 21);
}

.tablinks button {
  padding: 10px 0;
  border: none;
  background: transparent;
  border-radius: 10px;
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 17px;
  transition: all .3s;
}

.tablinks.active button img {
  -webkit-animation: x-floating-tab 1.5s ease-in-out infinite;
  animation: x-floating-tab 1.5s ease-in-out infinite;
}

@keyframes x-floating-tab {
  0% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(-5px) rotate(-1deg);
    transform: translateY(-5px) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
  }
}

.tablinks img {
  width: 60px;
  height: auto;
  margin-top: -6px;
  margin-right: 10px;
  transition: all .3s;
}

.ingridgame {
  width: 100%;
  padding: 5px;
  position: relative;
}

.ingridgame:hover {
  z-index: 10;
}

.ingridgame:hover img {
  transform: scale(1.05);
}

.section02 {
    /*
  background: url('./assets/images/041.jpeg') right center;
  background-size: cover;
  */
  background-color: #fff;
}

.section03 {
  background: url('./assets/images/bgstyle4.jpg') center center;
  background-size: cover;
  position: relative;
}

@media (max-width: 767px) {
  .tablinks button {
    font-size: 13px;
    padding: 5px 0;
  }

  .tablinks img {
    display: block;
    margin: 0 auto;
    width: 36px;
  }
}


.gridgame.alone {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.gridgame {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}

@media (max-width: 1199px) {
  .gridgame.alone {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1099px) {
  .gridgame {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  .gridgame {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .gridgame.alone {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .gridgame {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .gridgame.alone {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .linefixed {
    position: fixed;
    right: 10px;
    z-index: 100;
    width: 85px;
    height: auto;
    top: 75%;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }
  .linefixed img {
    display: block;
    margin: 0 auto;
    height:auto;
    width: 70px;
  }
}

@media (max-width: 390px) {
  .gridgame.alone {
    grid-template-columns: 1fr 1fr;
  }
}


.gridgame.second {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 991px) {
  .gridgame.second {
    grid-template-columns: 1fr;
  }
}




.contain02 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.iningridgame {
  width: 100%;
  position: relative;
  z-index: 1;
}

.iningridgame {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  height: 100%;
  background: #fff;
  border-radius: 10px;
}

.gridgame.alone .iningridgame:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border: 3px solid transparent;
  border-radius: 10px;
  background: linear-gradient(155deg, #d35a03, #e4911c);
  /*background-image: linear-gradient(169deg, #f5c90a, #b54808), linear-gradient(180deg, #a78059 -30%, #f2d7a9 22%, #715236 72%, #f1bb73 112%);*/
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.iningridgame img {
  width: 100%;
  transition: all .3s;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 10px black;
}


// top nav

.insidebarleft {
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(360deg, #011135a3, #01143dab), url('./assets/images/bgstyle5.jpg') center center;
  transition: all .3s;
  z-index: 1000;
  height: 100vh;
}

.insidebarleft i {
  font-size: 25px;
  width: 40px;
  padding: 5px 0;
  background: linear-gradient(45deg, #dfa041 50%, #f5daab 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.offcanvas {
  background-color: transparent !important;
}

.offcanvas.offcanvas-end {
  max-width: 300px;
}

.overlaysidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000b3;
  z-index: 999;
  display: none;
}

.x-hamburger, .x-hamburger span {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  top: 31px;
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 25px;
  left: 10px;
  transition: all .3s;
  z-index: 1;
}

.x-hamburger span:first-child {
  top: 0;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: x-hamburger-wave .4s ease-out infinite alternate;
  animation: x-hamburger-wave .4s ease-out infinite alternate;
}

.x-hamburger span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: x-hamburger-wave .4s ease-out .3s infinite alternate;
  animation: x-hamburger-wave .4s ease-out .3s infinite alternate;
}

.x-hamburger span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: x-hamburger-wave .4s ease-out .6s infinite alternate;
  animation: x-hamburger-wave .4s ease-out .6s infinite alternate;
}

.x-hamburger span {
  width: 25px;
  display: none;
  position: absolute;
  height: 3px;
  background: #dfdfdf;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transition: .25s ease-in-out;
}

.toplogin {
  position: relative;
  background: $bghead01;
}

.containtoplogin {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.topdetaillogin {
  font-size: 14px;
}

.toploginbox {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.toploginbox form {
  display: flex;
}

.el-input {
  position: relative;
}

.el-input i {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  padding: 5px 0;
  background: linear-gradient(45deg, #dfa041 50%, #f5daab 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.toploginbox input {
  max-width: 150px;
  margin-right: 10px;
}

.inputstyle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #00000038;
  border-radius: 8px;
  font-weight: 100;
  color: white;
  padding: 7px;
  padding-left: 35px !important;
  width: 100%;
  border: 1px solid #ffffff;
  box-shadow: 3px 3px 5px black;
}

.flexcenter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.toploginbox form .btn, .toploginbox .btn {
  padding: 0.75em 2em;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg, #a60522 0, #a40619);
  border-radius: 8px;
  font-weight: 200;
}

.toploginbox .btn {
  background: linear-gradient(180deg, #ffd600 0, #e08700);
}

.inputstyle:focus {
  background: #69625c91;
  border: none;
  color: white;
  outline: none;
}

.toploginbox form .btn:hover {
  background: linear-gradient(180deg, #a60522, #bd071c);
}

.toploginbox .btn:hover {
  background: linear-gradient(180deg, #ffde33, #ffa214);
}

.telheader {
  font-weight: 300;
  font-size: 17px;
  position: relative;
  display: block;
  text-align: center;
  color: #000;
}

.telheader img {
  width: 35px;
  height: auto;
  margin-top: -5px;
  position: absolute;
  left: -37px;
}

.-balance-container .-user-balance {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1.75rem;
  background: $bgbalance;
  border-radius: 30px;
}

.-balance-container .-ic-coin {
  position: absolute;
  top: 50%;
  left: -10px;
  width: 35px;
  height: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.-balance-container .-user-balance #customer-balance {
  font-weight: 300;
  font-size: 14px;
}

.-balance-container .-btn-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 15px;
}

.rightscb ul li i {
  font-size: 24px;
  display: block;
  padding: 3px;
  margin: 6px 0;
  margin-bottom: 0px;
  background: linear-gradient(45deg, #dfa041 50%, #f5daab 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 991px) {
  .telheader {
    margin-bottom: 5px;
  }

  .telheader img {
    left: -10px;
  }
}

@media (max-width: 767px) {
  .telheader {
    text-align: right;
    padding-right: 5px;
  }

  .telheader img {
    left: 10px;
  }
}


// secondary
.second-bar {
    background: linear-gradient(180deg,$bghead01,$bghead01sec);
  position: relative;
  box-shadow: 0 0 10px #00000045;
}

.ctscb {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  height: 82px;
}

.leftscb img {
  width: 100%;
  max-width: 200px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rightscb {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.rightscb ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.rightscb ul li {
  list-style: none;
  transition: all .3s;
  border-radius: 10px;
}

.rightscb ul li a {
  display: block;
  width: 87px;
  text-align: center;
  font-size: 13px;
  color: #000;
}

.rightscb ul li img {
  width: 40px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: -4px;
}

.insidebarleft ul {
  margin: 0;
  padding: 0;
  margin-top: 18px;
}

.insidebarleft li {
  text-align: left;
  list-style: none;
}

.insidebarleft li a {
  margin-top: 3px;
  display: block;
  padding: 10px;
  transition: all .3s;
  padding-left: 30px;
}

.insidebarleft li img {
  width: 100%;
  height: auto;
  max-width: 30px;
  margin: 0 auto;
  margin-right: 10px;
}

.second-bar .toploginbox a .btn:nth-child(1) {
  margin-left: 10px;
  background: linear-gradient(180deg, #a60522 0, #a60522);
}

.second-bar .toploginbox a:nth-child(2) .btn {
  background: linear-gradient(180deg, #ffd600 0, #e08700) !important;
}

.second-bar .toploginbox {
  display: none;
}

.contain03 {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 61px;
  background: #00000059;
  border-radius: 10px;
  padding-bottom: 15px;
}

.logosection img {
  width: 200px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  position: absolute;
  z-index: 1;
}

.leftsec03 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  order: 0;
  font-size: 14px;
}

.rightsec03 {
  order: 0;
}

.buttonboxsec03 {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.buttonboxsec03 img {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.leftdetailsec03 {
  white-space: pre-line;
}

.x-service-wrapper .-box {
  padding: 0;
}

.x-service-wrapper .-box a {
  text-decoration: none !important;
  color: #d2d2d2;
}

.x-service-wrapper .-box .-fake-bg {
  display: none;
}

.x-service-wrapper .-box .-ic-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.-ic-section02 img {
  width: 80px !important;
}

.Detailsec02 {
  width: 150px;
  margin: 0 auto;
  word-break: break-word;
  font-weight: 200;
  font-size: 14px;
}

.x-service-wrapper .-box .-title-h2, .x-service-wrapper .-box h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: 300;
}

@media (max-width: 991px) {
  .toplogin {
    display: none;
  }

  .rightscb {
    display: none;
  }

  .second-bar .toploginbox {
    display: flex;
    flex-direction: row;
  }

  .x-service-wrapper .-box {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .leftscb img {
    max-width: 34vw;
  }

  .second-bar .toploginbox .btn {
    font-size: 3.4vw;
  }
}

@media (max-width: 768px) {
  .rightsec03 img {
    width: 100%;
    max-width: 348px !important;
    display: block;
    margin: 0 auto;
  }

  .contain03 {
    padding: 20px;
    padding-top: 79px;
    background: transparent;
  }

  .rightsec03 {
    order: 0;
  }

  .leftsec03 {
    order: 1;
  }

  .x-service-wrapper .-box .-ic-wrapper {
    height: 100px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .Detailsec02 {
    width: auto;
    margin: 0 auto;
  }
}


// login page
section.sectionpage.login {
  max-width: 800px;
  position: relative;
  border: none;
  border-radius: 20px;
}

.sectionpage {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  border: solid 2px #ffe083;
  background: #0a0e2596;
  border-radius: 10px;
  margin-bottom: 20px;
}

section.sectionpage.login::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  bottom: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: 20px;
  background-image: linear-gradient(348deg, #8b8888, #eeeaea), linear-gradient(180deg, #a78059 -30%, #f2d7a9 22%, #715236 72%, #f1bb73 112%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.bginputlogin {
  padding: 20px;
  border-radius: 19px;
  font-size: 16px;
  position: relative;
  transition: all .4s;
}

.ic-lg-01, .ic-lg-02, .ic-lg-03 {
  position: absolute;
  width: 90px;
  z-index: 999;
}

.ic-lg-01 {
  width: 10%;
  top: -14px;
  left: -10px;
  -webkit-animation: x-sexy-up-down-loop 4s ease-in-out infinite;
  animation: x-sexy-up-down-loop 4s ease-in-out infinite;
}

.ic-lg-02 {
  width: 10%;
  bottom: -44px;
  right: 0px;
  -webkit-animation: x-sexy-up-down 3s 1s infinite alternate;
  animation: x-sexy-up-down 3s 1s infinite alternate;
}

.logologin img {
  max-width: 200px;
}

.bginputlogin .logologin {
  z-index: 99;
  position: relative;
}

.logologin {
  text-align: center;
}

.inbgbeforelogin {
  z-index: 99;
  position: relative;
}

.inbgbeforelogin {
  padding: 0 20px;
}

.headerlogin {
  text-align: center;
}

.headerlogin h2 {
  display: inline-block;
  background: linear-gradient(180deg, #b38c2c 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  margin-top: 10px;
  font-size: 25px;
}

.loginbtn {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #ffe18c;
  color: #ffe18c;
}

.wantregister {
  margin-top: 25px;
  font-weight: 100;
  color: white;
  text-align: center;
  font-size: 16px;
}

@-webkit-keyframes x-sexy-up-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px)
  }
}

@keyframes x-sexy-up-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px)
  }
}

@-webkit-keyframes x-sexy-up-down-loop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}


@keyframes x-sexy-up-down-loop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@media (max-width: 768px) {
  .bginputlogin {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 10px 10px;
    box-shadow: 0 0 10px #00000059;
  }

  .ic-lg-01 {
    width: 13%;
    top: 11px;
    left: 4px;
  }

  .insidectloginmb {
    position: relative;
  }
}

.headregislogin {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 15px auto;
  margin-bottom: 0;
  position: relative;
  padding: 0 20px;
}

.headregislogin .active img.gif {
  display: inline;
}

.headregislogin img {
  display: none;
}

.headregislogin img {
  width: 100%;
  mix-blend-mode: luminosity;
  opacity: .8;
  cursor: pointer;
  animation-play-state: paused;
}

.headregislogin .active img {
  mix-blend-mode: inherit;
  opacity: 1;
  display: none;
}

.headregislogin img.png {
  display: inline;
}

.headregislogin img {
  display: none;
}

.headregislogin img {
  width: 100%;
  mix-blend-mode: luminosity;
  opacity: .8;
  cursor: pointer;
  animation-play-state: paused;
}

.headregislogin img {
  display: none;
}

.headregislogin img {
  width: 100%;
  mix-blend-mode: luminosity;
  opacity: .8;
  cursor: pointer;
  animation-play-state: paused;
}

.headregislogin img.png {
  display: inline;
}

.headregislogin img {
  display: none;
}

.headregislogin img {
  width: 100%;
  mix-blend-mode: luminosity;
  opacity: .8;
  cursor: pointer;
  animation-play-state: paused;
}

.headregislogin .active img {
  mix-blend-mode: inherit;
  opacity: 1;
  display: none;
}


// register
.inbgbeforelogin input, .inbgbeforelogin select {
  background: #000;
  width: 100%;
  border-radius: 9px;
  border: none;
  padding: 10px;
  outline: none;
  color: rgb(236, 20, 20);
  box-shadow: 0 0 2px white, 0 0 15px #0000009e;
  font-weight: 200;
}
.inbgbeforelogin div{
  color: rgb(236, 20, 20);
}

.inbgbeforelogin .logopopup img {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 20px;
}

.inbgbeforelogin h1 {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  margin-top: 10px;
  display: block;
  background: linear-gradient(180deg, #b38c2c 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.btnLogin {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  background: linear-gradient(180deg, #fff287, #9d6a29);
  display: block;
  margin: 10px auto;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 20px;
  color: white;
  border: none;
}

.modalspanbox {
  text-align: center;
  font-weight: 200;
  padding: 10px 0;
  padding-top: 0px;
}


// profile
.headsecion {
  text-align: center;
  margin: 10px 0;
  font-size: 30px;
  font-weight: 400;
  background: linear-gradient(180deg, #ffe29b 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ctpersonal.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ctpersonal {
  padding: 15px;
  border-radius: 20px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 0;
}

.boxpsl {
  margin-bottom: 15px;
}

.boxpsl span {
  display: block;
}

.boxpsl input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #173365;
  outline: none;
  font-weight: 100;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 10px;
  color: #ffeb91;
}

.toploginbox .btn.blue {
  background: linear-gradient(180deg, #028936 0, #016126);
}

@media (max-width: 768px) {
  .ctpersonal.grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}

// cashback
.headsecion img {
  width: 30px;
  height: auto;
  margin-top: -6px;
}

.headtwocb {
  font-size: 27px;
  font-weight: 500;
  text-align: center;
}

.cashbacknb {
  font-size: 30px;
  text-align: center;
  background: linear-gradient(180deg, #ffe29b 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


// promotion
.ctpersonal.promotion {
  max-width: 1100px;
  padding-top: 0;
}

.gridingame.full {
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .gridingame.full {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1099px) {
  .gridingame {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  .gridingame {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .gridingame {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

.gridingame.full .ingridgame .iningridgame img {
  transform: scale(1);
}


.gridingame.full .ingridgame {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.gridingame.full .ingridgame {
  padding: 9px 5px;
}

.gridingame {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}

.gridingame.full .ingridgame .iningridgame {
  display: flex;
  justify-content: flex-start;
  background: transparent;
}

.accordion-header {
  background-color: #0000008a;
}

.accordion-body {
  color: white;
  background-color: #0000008a;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: max-height 0.2s ease-out;
  box-shadow: inset 0 0 10px black;

}

.accordion-body h5 {
  background: linear-gradient(180deg, #ffe29b 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion-button, {
  padding: 0;
  background-color: transparent;
  border: none !important;
}

.accordion-item, .accordion-button:not(.collapsed) {
  padding: 0;
  background-color: transparent;
  border: none !important;
}

.accordion-button::after {
  display: none;
}


.gridingame.full .ingridgame button.btnLogin {
  position: relative;
  transform: none;
  display: block;
  top: auto;
  left: auto;
  border: none;
  background: linear-gradient(180deg, #ffd600 0, #e08700);
  margin: 0 auto;
  width: 100%;
}


// deposit
.banktabicon {
  width: 100%;
  max-width: 60px;
  display: block;
  margin: 0 auto;
}

.leftdps {
  box-shadow: 3px -2px 7px #00000052;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.leftdps .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background: linear-gradient(180deg, #ffd600 0, #e08700) !important;
  font-weight: 500;
}

.leftdps .nav-pills .nav-link {
  padding: 20px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 200;
}

.leftdps .nav-pills .nav-link {
  padding: 20px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 200;
}

.tab-content > .active {
  display: block;
}

.griddps {
  display: grid;
  grid-template-columns: 1fr;
}

.ingriddps {
  padding: 20px;
}

.iningriddps {
  text-align: center;
  font-size: 14px;
  color: #efefef;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.iningriddps img {
  width: 90px;
  margin-bottom: 5px;
  margin-right: 25px;
}

.iningriddps2 img {
  width: 65px;
  margin-bottom: 5px;
 
}
.kipsx{
  display:block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid red;
}
.iningriddps2 button {
  margin-top: 5px;
  border: none;
  font-weight: 300;
  border-radius: 5px;
  color: white;
  background: linear-gradient(180deg, #ffd600 0, #e08700) !important;
  padding: 3px 15px;
}

.iningriddps span {
  text-align: center;
  font-size: 14px;
  color: white;
  font-weight: 300;
}

.modalspanbox span {
  color: #f9c602;
  font-weight: 400;
}
.moneyBtn3 {
  outline: none;
  border: none;
  width: 100%;
  height: 43px;
  border-radius: 15px;
  border: #06b2af 2px solid;
  /*background: linear-gradient(90deg, rgb(6, 205, 175) 0%, rgb(2, 159, 125) 100%);*/
  color: #fff;
  font-size: 18px;
  text-align: right;
  padding-top: 5px;
  padding-right: 10px;
  transition: all .5s;
  opacity: 1;
  margin-top: 4px;
  font-weight: 300;
}
.moneyBtn4 {
  outline: none;
  border: none;
  width: 100%;
  height: 43px;
  border-radius: 15px;
  border: #e90d0d 2px solid;
  /*background: linear-gradient(90deg, rgb(221, 176, 16) 0%, rgb(244, 173, 22) 100%);*/
  color: #fff;
  font-size: 18px;
  text-align: right;
  padding-top: 5px;
  padding-right: 10px;
  transition: all .5s;
  opacity: 1;
  margin-top: 4px;
  font-weight: 300;
}
.moneyBtn2 {
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  
  background: linear-gradient(90deg, rgb(219, 2, 2) 0%, rgb(163, 27, 2) 100%);
  color: #fcf9f9;
  font-size: 18px;
  transition: all .5s;
  opacity: 1;
  margin-top: 4px;
  font-weight: 300;
}
// withdraw
.moneyBtn {
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(255, 179, 0, 1) 0%, rgba(234, 223, 126, 1) 100%);
  color: #000;
  font-size: 18px;
  transition: all .5s;
  opacity: 1;
  margin-top: 32px;
  font-weight: 300;
}

.inboxmain {
  font-weight: 200;
}

.inputmain {
  background: transparent;
  border: none;
  width: 100%;
  font-size: 20px;
  font-weight: 100;
  outline: none;
  color: white;
}

.circlered {
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
}

// history
.ctpersonal.trans {
  background: transparent;
  border: none;
}

.item-withdraw {
  box-shadow: 0 0 0px 1px #bd4c4c;
  background: linear-gradient(177deg, #7e3e3e9e, #270b0ba8);
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 15px;
  margin-bottom: 10px;
}

.item-deposit {
  box-shadow: 0 0 0px 1px #62986d;
  background: linear-gradient(177deg, #3e7e4c9e, #0b2712a8);
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.game-history {
  background: linear-gradient(177deg, #1651b4ab, #02131ca8);
  box-shadow: 0 0 0px 1px #62986d;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.history .tab-pane {
  height: 60vh;
  overflow: auto;
}


// affiliate
.affilicate .header {
  display: inline;
  color: #ffe690;
  padding: 5px 10px;
  border-radius: 20px;
  background: #202331;
}

.account-card {
  width: 100%;
  max-width: 500px;
  background: linear-gradient(122deg, #225db9, #041d4a);
  padding: 10px 30px;
  margin: 0 auto;
  color: #c7c7c7;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: 0 0 10px black;
}

.account-card .bank-logo {
  width: 55px;
}

.income {
  font-size: 25px;
  color: #ffe690;
  margin-top: -7px;
  font-weight: 300;
}

.account-card .btn-withdraw {
  background: linear-gradient(180deg, #ffd600 0, #e08700) !important;
  box-shadow: 0 0 10px #000000;
  border: none;
  border-radius: 10px;
  width: 100%;
  color: white;
  text-align: center;
}


.btn-copy {
  background: #0e1220;
  border-radius: 20px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  padding: 15px;
  cursor: pointer;
}


.containlinkfriend {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 15px auto;
}

.iconlogin {
  color: #ffffff;
  position: absolute;
  margin-top: 13px !important;
  margin-left: 12px;
}

.loginform01:read-only {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0e1220;
  border-radius: 20px;
  color: white;
  padding-left: 40px;
  border: none !important;
}

.loginform01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background: #202331;
  border: none !important;
  border-radius: 20px;
  color: white;
  padding: 13px 13px 13px 40px;
  box-shadow: none !important;
  outline: none !important;
}

.copylink {
  cursor: pointer;
}

.wrapgrid001 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  padding: 0 10px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 500px;
}

.ininwrapgrid001.active {
  background: linear-gradient(180deg, #ffd600 0, #e08700) !important;
  border-radius: 5px;
}

.inwrapgrid001 {
  padding: 0 5px;
  margin-top: 5px;
}

.ininwrapgrid001 {
  background: #424867;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 5px 0;
}

.containinputwd table {
  width: 100%;
}

.levelfriend {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 15px;
  white-space: nowrap;
}

.detailaf {
  padding: 5px 10px;
  font-size: 15px;
  background: linear-gradient(to right, #49506d 0%, #2c344c 100%);
  border-radius: 4px;
  color: #fff;
}

.indetail {
  font-size: 16px;
  color: #d4d4d4;
  margin-top: 0;
  background: #0a0c13;
  box-shadow: inset 0 0 10px black;
  padding: 15px 5px;
  border-radius: 8px;
  padding-left: 10px;
  padding-top: 15px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: -10px;
}


.containinputwd.active {
  display: block;
}

.containinputwd {
  display: none;
}

.divoffriends {
  text-align: center;
  background: #080a12;
  border-radius: 20px;
  margin-top: -10px;
  height: 330px;
  overflow: auto;
  padding: 0 10px;
  padding-top: 15px;
}


// mobile menu
#account-actions-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
}

#account-actions-mobile .-outer-wrapper {
  display: flex;
  align-items: flex-end;
}

#account-actions-mobile .-item-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  position: relative;
}

#account-actions-mobile .-left-wrapper {
  border-top-left-radius: 10px;
  border-top-right-radius: 22px;
}

#account-actions-mobile .-left-wrapper, #account-actions-mobile .-right-wrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
  z-index: 2;
  height: 70px;
  padding-bottom: 6px;
  background: linear-gradient(180deg,$bghead01,$bghead01sec);
  border-top: 3px solid #ffffff;
  transition: -webkit-filter .2s;
  transition: filter .2s,
  -webkit-filter .2s;
}

#account-actions-mobile .-center-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  width: 74px;
  height: 74px;
  margin: 0 8px 16px;
  padding-bottom: 8px;
  color: #fff;
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background: linear-gradient(180deg,$bghead01cen,$bghead01cenb);
  box-shadow: 0 0 10px hsla(0, 0%, 100%, .4);
}

#account-actions-mobile .-item-wrapper .-textfooter {
  font-size: .875rem;
  color: #000;
  transition: color .2s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  margin: 0.25rem auto 0;
}

#account-actions-mobile .-item-wrapper .-textfooter a {
  color: #000;
  text-decoration: none;
}

#account-actions-mobile .-fake-center-bg-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 50px;
  transition: -webkit-filter .2s;
  transition: filter .2s, -webkit-filter .2s;
  overflow: hidden;
}

#account-actions-mobile .-center-wrapper .-selected, #account-actions-mobile .-center-wrapper .-selecting {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

#account-actions-mobile .-center-wrapper .-selected {
  transition: all .4s;
  background-size: 450px;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: 0 0;
  border-radius: 50%;
}

.-selected h2 a {
  position: absolute;
  bottom: 6px;
  font-size: 12px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 0 0 5px black;
  font-weight: 300;
}

#account-actions-mobile .-right-wrapper {
  border-top-right-radius: 10px;
  border-top-left-radius: 22px;
}

#account-actions-mobile .-fake-center-bg-wrapper svg {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 108px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#account-actions-mobile .-fake-center-bg-wrapper svg path {
  /*fill: url(#rectangleGradient);*/
}

.-ic-img img {
  width: 34px;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
}

.-selected img {
  width: 65px !important;
  max-width: 65px !important;
  height: auto;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.-selected h5 {
  position: absolute;
  bottom: 6px;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

.insidebarleft li a:hover {
  background: #0d0d0d;
  text-shadow: 0 0 5px white, 0 0 15px white;
}
